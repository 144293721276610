import React from 'react'
import SchoolServices from '../components/SchoolServices'
import SchoolExcellence from '../components/SchoolExcellence'
import CusNavbar from '../components/Navbar'
import StudentClub from '../components/StudentsClub'

function School() {
  return (
    <>
    <CusNavbar></CusNavbar>
    <SchoolServices></SchoolServices>
    <SchoolExcellence></SchoolExcellence>
    <StudentClub></StudentClub>
    </>
  )
}

export default School