import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Content1.css';

function Content1() {

  const phoneNumber = "+91 9871428686"; 

  const handleGetStartedClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <div className="content1-section">
      <div className="container py-4">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h1 className="display-4 mb-3">
              Great things can <span className="highlighted-text">happen</span>
              <br /> Pick up your phone, start the conversation.
            </h1>
            <p className="description-text">
              We are a team of experienced and expert technocrats, creative innovators, and technical wizards. Our passion lies in designing and developing exceptional websites that transform your ideas into reality.
            </p>
            <button className="cta-button mt-3" onClick={handleGetStartedClick}>Contact Us</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content1;
