import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import './Navbar.css';
import { IoMailOutline } from 'react-icons/io5';  // Using a professional mail icon from React Icons

function CusNavbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleScrollOrNavigate = (path, targetId) => {
    if (path === '/Contact-us') {
      navigate('/Contact-us');
      return;
    }
if (path ==='/Schools'){
  navigate('/Schools')
  return
}
    if (location.pathname === '/home') {
      if (targetId) {
        scroller.scrollTo(targetId, { smooth: true, duration: 10, offset: -70 });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      navigate(path, { state: { targetId } });
    }
  };

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Navbar expand="lg" className={`bg-light fixed-top ${isScrolled ? 'scrolled' : ''}`}>
      <div className="container-fluid">
        <Navbar.Brand onClick={() => handleScrollOrNavigate('/home')} style={{ cursor: 'pointer' }}>
          <img src="/Images/abslogo.png" alt="Logo" style={{ height: '25px', width: '100%' }} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggle" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => handleScrollOrNavigate('/home')} className="nav-item tab">
              Home
            </Nav.Link>
            <Nav.Link onClick={() => handleScrollOrNavigate('/home', 'about')} className="nav-item tab">
              About Us
            </Nav.Link>
            <Nav.Link onClick={() => handleScrollOrNavigate('/home', 'services')} className="nav-item tab">
              Services
            </Nav.Link>
            <Nav.Link onClick={() => handleScrollOrNavigate('/home', 'ventures')} className="nav-item tab">
              Ventures
            </Nav.Link>
            <Nav.Link onClick={() => handleScrollOrNavigate('/Schools', 'schools')} className="nav-item tab">
              Schools/Institutes
            </Nav.Link>
            {/* Replace Contact Us text with Mail Icon */}
            <Nav.Link onClick={() => handleScrollOrNavigate('/Contact-us')} className="nav-item tab">
              <IoMailOutline size={24} style={{ color: 'black', marginRight: '0.5rem' }} />
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default CusNavbar;
