import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './WhatsAppIcon.css';

function WhatsAppIcon() {
  return (
    <a
      href="https://wa.me/<9717024896>?text=Hello,%20I%20would%20like%20to%20inquire..."
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon"
    >
      <i className="fab fa-whatsapp"></i>
    </a>
  );
}

export default WhatsAppIcon;
